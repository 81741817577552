import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";

export function SELECT_EDUCTION_CLASS(state: State, selectedEducationClass: string | null) {
  state.selectedEducationClass = selectedEducationClass;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SELECT_EDUCTION_CLASS,
};
