import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update } from "@/store/Crud/functions/actions";

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  deleteAccount: update,
  findAll,
  getOne,
  resetSave,
};
