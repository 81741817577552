import { CrudState } from "@/store/Crud/State";

export function SET_DATA_LIST(state: CrudState, data: any) {
  state.dataList = data;
}
export function SET_TOTAL(state: CrudState, total: any) {
  state.total = total;
}

export function SET_DATA_ITEM(state: CrudState, item: any) {
  state.dataItem = item;
}

export function SET_ERROR(state: CrudState, error: any) {
  state.error = error;
}

export function IS_LOADING(state: CrudState, isLoading: any) {
  state.isLoading = isLoading;
}

export function SET_SUCCESS(state: CrudState, success: any) {
  state.success = success;
}

export function SET_DATA_ID(state: CrudState, id: number) {
  state.id = id;
}
