import { ActionTree } from "vuex";
import { State } from "./state";
import axios from "@/utils/axios";

export const sendThirdPartyPaymentEmail = async (store: any, data: any): Promise<any> => {
  store.commit("SET_SEND_THIRD_PARTY_PAYMENT_LOADING", true);
  store.commit("SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS", null);
  const request: Promise<any> = axios
    .post("/live-pay/send-third-party-payment-email", data)
    .then((response: any) => {
      store.commit("SET_SEND_THIRD_PARTY_PAYMENT_LOADING", false);
      store.commit("SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS", true);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("SET_SEND_THIRD_PARTY_PAYMENT_LOADING", false);
      store.commit("SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS", false);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    });

  return request;
};

export const setError = async (store: any, error: any): Promise<void> => {
  store.commit("SET_ERROR", error);
};

export const setSendThirdPartyPaymentEmailSuccess = async (store: any, success: any): Promise<void> => {
  store.commit("SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS", success);
};

export const setSendThirdPartyPaymentEmailLoading = async (store: any, loading: any): Promise<void> => {
  store.commit("SET_SEND_THIRD_PARTY_PAYMENT_LOADING", loading);
};

export const setSelectedEducation = async (store: any, selectedEducation: any): Promise<void> => {
  store.commit("SET_SELECTED_EDUCATION", selectedEducation);
};

export const reset = async (store: any): Promise<void> => {
  store.commit("SET_SELECTED_EDUCATION", null);
  store.commit("SET_ERROR", null);
  store.commit("SET_SEND_THIRD_PARTY_PAYMENT_LOADING", false);
  store.commit("SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS", null);
};

export default <ActionTree<State, any>>{
  setError,
  setSendThirdPartyPaymentEmailSuccess,
  setSendThirdPartyPaymentEmailLoading,
  setSelectedEducation,
  sendThirdPartyPaymentEmail,
  reset,
};
