import { App, Plugin } from "vue";
//@ts-ignore
import T from "vue-toasted/dist/toasted.js";
//@ts-ignore
import ToastedComponent from "vue-toasted/dist/vue-toasted.js";
import { Toasted, ToastOptions } from "vue-toasted";

export const createToasted = (options: ToastOptions = {}): Toasted => {
  const Toast = new T(options);
  return {
    show: Toast.show,
    success: Toast.success,
    info: Toast.info,
    error: Toast.error,
    register: Toast.register,
    clear: Toast.clear,
    global: Toast.global,
  };
};

const Vue3ToastedPlugin: Plugin = {
  install: function (app: App, options: any = {}) {
    app.config.globalProperties.$toasted = createToasted(options);
    app.component("toasted", ToastedComponent);
  },
};

export default Vue3ToastedPlugin;
