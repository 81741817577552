import { format as moneyFormat } from "@/utils/money";

export default {
  methods: {
    format(value: any): string {
      const moneySuffix = "€";
      const moneyOption = {
        decimal: ",",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      };
      return value !== "" ? `${moneyFormat(value, moneyOption)} ${moneySuffix}` : "";
    },
  },
};
