import { ActionTree } from "vuex";
import { State } from "./state";
import { create, dispatchGlobalError, filter, findAll, findOne, getOne, resetSave, update } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ANSWER_SUCCESS, SET_ERROR } from "@/store/Appointments/types";
import axios from "@/utils/axios";

export const addTheoryPreExamQuestionAnswers = async (store: any, body: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_ANSWER_SUCCESS, null);
  return await axios
    .post("theory-pre-exams/add-question-answers/", body)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ANSWER_SUCCESS, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      dispatchGlobalError(store);
    });
};

export const createPreExam = async (store: any, studentEducationId: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit("SET_DATA_ITEM", null);
  return await axios
    .post("theory-pre-exams?studentEducationId=" + studentEducationId)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_DATA_ITEM", data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      dispatchGlobalError(store);
    });
};

// @deprecated?
export const setAnswerSuccess = (store: any, success: boolean) => store.commit("SET_ANSWER_SUCCESS", success);
export const setQuestions = (store: any, questions: any) => store.commit("SET_QUESTIONS", questions);
export const setBasicQuestions = (store: any, questions: any) => store.commit("SET_BASIC_QUESTIONS", questions);
export const setSpecificQuestions = (store: any, questions: any) => store.commit("SET_SPECIFIC_QUESTIONS", questions);

export const resetExam = (store: any) => {
  store.commit("SET_ANSWER_SUCCESS", null);
  store.commit("SET_QUESTIONS", []);
  store.commit("SET_BASIC_QUESTIONS", []);
  store.commit("SET_SPECIFIC_QUESTIONS", []);
  store.commit("SET_DATA_ITEM", null);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  createPreExam,
  addTheoryPreExamQuestionAnswers,
  setAnswerSuccess,
  setQuestions,
  setBasicQuestions,
  setSpecificQuestions,
  resetExam,
};
