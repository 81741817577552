import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_ITEM, SET_DATA_LIST, SET_TOTAL, SET_DATA_ID } from "@/store/Crud/functions/mutations";

export function SET_SELECTED_THEORY_STUDENT_EDUCATION(state: State, selected: any) {
  state.selectedTheoryStudentEducation = selected;
}

export function SET_SELECTED_PREEXAM_STUDENT_EDUCATION(state: State, selected: any) {
  state.selectedPreExamStudentEducation = selected;
}

export function SET_SELECTED_ACCOUNT_STUDENT_EDUCATION(state: State, selected: any) {
  state.selectedAccountStudentEducation = selected;
}
export function SET_SELECTED_PLAYGROUND_STUDENT_EDUCATION(state: State, selected: any) {
  state.selectedPlaygroundStudentEducation = selected;
}

export function SET_LEARNING_MODE(state: State, learningMode: any) {
  state.learningMode = learningMode;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_SELECTED_THEORY_STUDENT_EDUCATION,
  SET_SELECTED_PREEXAM_STUDENT_EDUCATION,
  SET_SELECTED_ACCOUNT_STUDENT_EDUCATION,
  SET_SELECTED_PLAYGROUND_STUDENT_EDUCATION,
  SET_LEARNING_MODE,
};
