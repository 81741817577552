import { GetterTree } from "vuex";
import { State } from "./state";
import { IGlobalMessage } from "@/interfaces/IGlobalMessage";

export function getMessage(state: State): IGlobalMessage {
  return state.message;
}
export default <GetterTree<State, any>>{
  getMessage,
};
