import StorageService from "@/services/StorageService";
import CryptoService from "@/services/CryptoService";
import { isRemember } from "@/helper/RememberMe";
import store from "@/store";

export default class UserService {
  private static getStorage() {
    return isRemember() ? localStorage : sessionStorage;
  }

  public static getUser(): any {
    return this.getFromStorage("user");
  }

  public static getToken(): any {
    return this.getStorage().getItem("token");
  }

  public static getRefreshToken(): any {
    return this.getFromStorage("refToken");
  }

  public static getTokenInfo(): any {
    return UserService.getFromStorage("tokenInfo");
  }

  public static setUser(data: any): void {
    const encode = CryptoService.encode(JSON.stringify(data));
    this.getStorage().setItem("user", String(encode));
  }

  public static setRefreshToken(data: any): void {
    const encode = CryptoService.encode(JSON.stringify(data));
    this.getStorage().setItem("refToken", String(encode));
  }

  public static setTokenInfo(data: any): void {
    const encode = CryptoService.encode(JSON.stringify(data));
    this.getStorage().setItem("tokenInfo", String(encode));
  }

  public static clearUser(): void {
    this.getStorage().removeItem("user");
    this.getStorage().removeItem("token");
    this.getStorage().removeItem("refToken");
  }

  public static listenLocalStorageAndClearUser(): void {
    StorageService.listenStorage(async (val: StorageEvent) => {
      if ((val.key == "user" || val.key == "token") && val.newValue == null) {
        this.clearUser();
        await store.dispatch("auth/signOut").then(() => window.location.reload());
      }
    });
  }

  public static getRole(): string | undefined {
    return this.getTokenInfo()?.role;
  }

  static getFromStorage(key: string): any {
    const data = this.getStorage().getItem(key);

    if (!data) {
      return null;
    }
    const decode = CryptoService.decode(data.toString());

    if (!decode) {
      return null;
    }
    return JSON.parse(decode);
  }

  public static getDrivingSchoolId(): number | undefined {
    return this.getTokenInfo()?.drivingSchoolId;
  }
}
