import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_ITEM } from "./types";

export default <MutationTree<State<any>>>{
  [IS_LOADING](state: State<any>, isLoading: boolean) {
    Object.assign(state, { isLoading });
  },

  [SET_ERROR](state: State<any>, error: Record<string, any> | null) {
    Object.assign(state, { error });
  },

  [SET_SUCCESS](state: State<any>, success: boolean) {
    Object.assign(state, { success });
  },

  [SET_DATA_ITEM](state: State<any>, dataItem: any) {
    Object.assign(state, { dataItem });
  },
};
