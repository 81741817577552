import { ActionTree } from "vuex";
import { State } from "./state";
import { create, dispatchGlobalError, update, findAll, findOne } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import clone from "lodash/clone";

export const findGroups = async (store: any, params: any): Promise<any> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_ERROR", null);

  const questionGroups = axios.get(`question-groups`);
  const questionNextVersionCount = axios.get(`/theory-questions/next-version/count`);

  return Promise.all([questionGroups, questionNextVersionCount])
    .then(([groups, nextQuestionCount]) => {
      const g = clone(groups.data);
      const n = clone(nextQuestionCount.data);
      //NEW_QUESTIONS
      Object.assign(g, {
        NEW_QUESTIONS: n,
      });
      store.commit("SET_GROUPS", g);
    })
    .catch((error) => {
      console.log(error.response.data);
    })
    .finally(() => {
      store.commit("IS_LOADING", false);
    });
};

export const findQuestionsByGroup = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit("IS_LOADING", true);

  return await axios
    .get(options.resource)
    .then(({ data }) => {
      store.commit("SET_QUESTIONS", data);
      store.commit("SET_ERROR", null);
      store.commit("IS_LOADING", false);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
      dispatchGlobalError(store);
    });
};

export const findQuestionsByGroupPaged = async (store: any, params: any): Promise<void> => {
  store.commit("IS_LOADING", true);

  return await axios
    .get(`question-groups/${params.group}/question?studentEducationId=${params.studentEducationId}`)
    .then(({ data }) => {
      store.commit("SET_QUESTIONS", data.data);
      store.commit("SET_TOTAL", data.total);
      store.commit("SET_ERROR", null);
      store.commit("IS_LOADING", false);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
      dispatchGlobalError(store);
    });
};

export const setQuestions = async (store: any, questions: any): Promise<void> => {
  store.commit("SET_QUESTIONS", questions);
};

export const reset = async (store: any, params: any): Promise<void> => {
  store.commit("SET_GROUPS", {});
  store.commit("SET_QUESTIONS", []);
  store.commit("SET_ERROR", null);
  store.commit("IS_LOADING", false);
};
export const resetGroups = async (store: any, params: any): Promise<void> => {
  store.commit("SET_GROUPS", {});
  store.commit("SET_ERROR", null);
  store.commit("IS_LOADING", false);
};
export const resetQuestions = async (store: any, params: any): Promise<void> => {
  store.commit("SET_QUESTIONS", []);
  store.commit("SET_ERROR", null);
  store.commit("IS_LOADING", false);
  store.commit("SET_TOTAL", 0);
};

export default <ActionTree<State, any>>{
  create,
  update,
  findGroups,
  findQuestionsByGroup,
  findQuestionsByGroupPaged,
  setQuestions,
  reset,
  resetGroups,
  resetQuestions,
  findAll,
  findOne,
};
