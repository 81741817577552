import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
// @ts-ignore
import queryString from "query-string";

/**
 *Basic CRUD Service
 */

export default class CrudService {
  /**
   *
   * @param options
   */
  public async getOne(options: ICrudOptions): Promise<any> {
    let url = options.resource;
    if (options.extraPath) {
      url += "/" + options.extraPath;
    }
    if (options.params) {
      const queryBuilder = queryString.stringify(options.params);
      url += "?" + queryBuilder;
    }
    return axios.get(url);
  }

  /**
   *
   * @param options
   */
  public async findOne(options: ICrudOptions): Promise<any> {
    let url = options.resource;
    if (options.extraPath) {
      url += "/" + options.extraPath;
    }
    if (options?.id) {
      url += "/" + options.id;
    }
    if (options.params) {
      const queryBuilder = queryString.stringify(options.params);
      url += "?" + queryBuilder;
    }
    return axios.get(url);
  }

  /**
   *
   * @param options
   */
  public async filter(options: ICrudOptions): Promise<any> {
    return axios.post(`/${options.resource}/filter`, options.filter);
  }

  /**
   *
   * @param options
   */
  public async findAll(options: ICrudOptions): Promise<any> {
    let url = options.resource;
    if (options.extraPath) {
      url += "/" + options.extraPath;
    }
    if (options.params) {
      const queryBuilder = queryString.stringify(options.params);
      url += "?" + queryBuilder;
    }
    return axios.get(url);
  }

  /**
   *
   * @param options
   */
  public async paginate(options: ICrudOptions): Promise<any> {
    return axios.get(options.resource + "/" + options.params.limit + "/" + options.params.offset);
  }

  /**
   *
   * @param options
   */
  public async create(options: ICrudOptions): Promise<any> {
    let url = options.resource;
    if (options.extraPath) {
      url += "/" + options.extraPath;
    }
    if (options.params) {
      const queryBuilder = queryString.stringify(options.params);
      url += "?" + queryBuilder;
    }
    return axios.post(url, options.data);
  }

  /**
   *
   * @param options
   */
  public async update(options: ICrudOptions): Promise<any> {
    let url = options.resource;
    if (options.extraPath) {
      url += "/" + options.extraPath;
    }
    if (options.id) {
      url += "/" + options.id;
    }
    if (options.params) {
      const queryBuilder = queryString.stringify(options.params);
      url += "?" + queryBuilder;
    }
    return axios.put(url, options.data);
  }

  /**
   *
   * @param options
   */
  public async delete(options: ICrudOptions): Promise<any> {
    return axios.delete(options.resource + "/" + options.id);
  }
}
