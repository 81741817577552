import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, update, getOne, resetSave, dispatchGlobalError } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "@/store/Appointments/types";
import axios from "@/utils/axios";

export async function getStats(store: ActionContext<any, any>, id: number): Promise<void> {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`student-question-answers/stats?studentEducationId=${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_STATS", data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_STATS", null);
      store.commit(SET_ERROR, response.data);
      dispatchGlobalError(store);
    });
}

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  getStats,
  resetSave,
};
