import CryptoJS from "crypto-js";

export default class CryptoService {
  // @ts-ignore
  private static readonly secretKey: string = process.env.VUE_APP_CRYPTO_SECRET;

  public static encode(value: string): any {
    return CryptoJS.AES.encrypt(value, this.secretKey);
  }

  public static decode(value: string): string {
    return CryptoJS.AES.decrypt(value, this.secretKey).toString(CryptoJS.enc.Utf8);
  }
}
