import { Module, ModuleTree } from "vuex";
import Item from "@/store/Abstract/Item";

export default class EmailConfirmation implements Module<any, any> {
  namespaced = true;
  modules: ModuleTree<any>;

  constructor() {
    this.modules = {
      confirmation: new Item(),
      resendConfirmation: new Item(),
    };
  }
}
