import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";

export const getSelectedTheoryStudentEducation = (state: State) => state.selectedTheoryStudentEducation;

export const getSelectedPreExamStudentEducation = (state: State) => state.selectedPreExamStudentEducation;

export const getSelectedAccountStudentEducation = (state: State) => state.selectedAccountStudentEducation;

export const getSelectedPlaygroundStudentEducation = (state: State) => state.selectedPlaygroundStudentEducation;

export const getLearningMode = (state: State) => state.learningMode;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getSelectedTheoryStudentEducation,
  getSelectedPreExamStudentEducation,
  getSelectedAccountStudentEducation,
  getSelectedPlaygroundStudentEducation,
  getLearningMode,
};
