import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";

export const getTheoryCourses = (state: State) => state.theoryCourses;
export const getLicenceClasses = (state: State) => state.licenceClasses;
export const getDrivingSchool = (state: State) => state.drivingSchool;
export const getBranches = (state: State) => state.branches;
export const getLanguages = (state: State) => state.languages;
export const getLanguagesLoading = (state: State) => state.languagesLoading;
export const getLanguagesError = (state: State) => state.languagesError;
export const getCountries = (state: State) => state.countries;
export const getCountriesLoading = (state: State) => state.countriesLoading;
export const getCountriesError = (state: State) => state.countriesError;
export const getPreviousLicenceClassesError = (state: State) => state.previousLicenceClassesError;
export const getPreviousLicenceClassesLoading = (state: State) => state.previousLicenceClassesLoading;
export const getPreviousLicenceClasses = (state: State) => state.previousLicenceClasses;

export const getSelectedLicenseClass = (state: State) => state.selectedLicenseClass;
export const getSelectedTheoryCourse = (state: State) => state.selectedTheoryCourse;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getTheoryCourses,
  getLicenceClasses,
  getDrivingSchool,
  getBranches,
  getLanguages,
  getLanguagesLoading,
  getLanguagesError,
  getCountries,
  getCountriesLoading,
  getCountriesError,
  getPreviousLicenceClassesError,
  getPreviousLicenceClassesLoading,
  getPreviousLicenceClasses,
  getSelectedLicenseClass,
  getSelectedTheoryCourse,
};
