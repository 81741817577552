import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update } from "@/store/Crud/functions/actions";

export const setPreview = async (store: any, preview: boolean): Promise<void> => {
  store.commit("SET_PREVIEW", preview);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  setPreview,
};
