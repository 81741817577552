import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_ITEM, SET_DATA_LIST, SET_TOTAL, SET_DATA_ID } from "@/store/Crud/functions/mutations";

import { SET_DATA_EXAM, SET_DATA_LIST_BY_COURSE } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,

  [SET_DATA_EXAM](state: State, exams: Array<any>) {
    Object.assign(state, { exams });
  },

  [SET_DATA_LIST_BY_COURSE](state: State, studentsByCourse: any) {
    Object.assign(state, { studentsByCourse });
  },
};
