import { GetterTree } from "vuex";
import { CrudState } from "@/store/Crud/State";

export const getError = (state: CrudState) => state.error;
export const getSuccess = (state: CrudState) => state.success;
export const getIsLoading = (state: CrudState) => state.isLoading;
export const getDataItem = (state: CrudState) => state.dataItem;
export const getDataList = (state: CrudState) => state.dataList;
export const getTotal = (state: CrudState) => state.total;
export const getId = (state: CrudState) => state.id;

export default <GetterTree<CrudState, any>>{
  getDataList,
  getTotal,
  getDataItem,
  getError,
  getIsLoading,
  getSuccess,
  getId,
};
