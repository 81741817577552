import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, getOne, update } from "@/store/Crud/functions/actions";
import { SET_SUCCESS } from "./types";

export const resetSuccess = (store: any): void => {
  store.commit(SET_SUCCESS, false);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  getOne,
  update,
  findAll,
  resetSuccess,
};
