import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update } from "@/store/Crud/functions/actions";

export const setSelectedEducationClass = (store: ActionContext<any, any>, selectedEducationClass: string | null): void => {
  store.commit("SELECT_EDUCTION_CLASS", selectedEducationClass);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  setSelectedEducationClass,
};
