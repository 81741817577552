import { ActionTree } from "vuex";
import { State } from "./state";
import { GOCARDLESS } from "@/store/Gocardless/types";

export function setGocardless(context: any, payload: boolean) {
  context.commit(GOCARDLESS, payload);
}

export default <ActionTree<State, any>>{
  setGocardless,
};
