import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM, SET_DATA_LIST, SET_DATA_EXAM, SET_SUCCESS } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, filter, findAll, dispatchGlobalError } from "@/store/Crud/functions/actions";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const URL = "/students";

export const uploadDocument = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", null);
  store.commit("SET_ERROR", null);
  return await axios
    .post(options.resource, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit("SET_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit("SET_SUCCESS", false);

      dispatchGlobalError(store);
    });
};

export const list = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL}/student-list`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);

      dispatchGlobalError(store);
    });
};

export const findExamAndEducationByStudentId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/exam-and-education/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_EXAM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);

      dispatchGlobalError(store);
    });
};

export const findByInstructor = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/by-instructor/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);

      dispatchGlobalError(store);
    });
};

export const findStudentsByTheoryCourse = async (store: ActionContext<any, any>, id: number) => {
  store.commit("IS_LOADING", true);
  return await axios
    .get(`theory-courses/${id}/students`)
    .then((response: AxiosResponse) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST_BY_COURSE", response.data);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", error.response?.data);

      dispatchGlobalError(store);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  uploadDocument,
  list,
  findExamAndEducationByStudentId,
  findByInstructor,
  findStudentsByTheoryCourse,
};
