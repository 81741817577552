import { MutationTree } from "vuex";
import { State } from "./state";
import { IGlobalMessage } from "@/interfaces/IGlobalMessage";

export function MESSAGE(state: State, message: IGlobalMessage) {
  state.message = message;
}

export default <MutationTree<State>>{
  MESSAGE,
};
