import { State } from "./state";
import { GetterTree } from "vuex";

export const getError = (state: State<any>): Record<any, any> | null => state.error;
export const getSuccess = (state: State<any>) => state.success;
export const getIsLoading = (state: State<any>) => state.isLoading;
export const getDataList = (state: State<any>) => state.dataList;
export const getTotal = (state: State<any>) => state.total;

export default <GetterTree<State<any>, any>>{
  getError,
  getIsLoading,
  getSuccess,
  getDataList,
  getTotal,
};
