import { ActionContext } from "vuex";
import CrudService from "@/services/CrudService";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import i18n from "@/i18n";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import { AxiosError } from "axios";
import isString from "lodash/isString";

/**
 * Basic CRUD actions
 */
export default class CrudActions extends MessageDispatcher {
  protected crudService: CrudService = new CrudService();

  constructor() {
    super();
  }

  /**
   * @param store
   * @param options
   */
  public async getOne(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", !options.disabledLoading);
    const request: Promise<any> = this.crudService.getOne(options);

    request
      .then((response: any) => {
        this.resolveSingleAction(store, response, options);
      })
      .catch((error: AxiosError) => {
        this.rejectReadAction(store, error, options);
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async findOne(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", !options.disabledLoading);
    const request: Promise<any> = this.crudService.findOne(options);

    request
      .then((response: any) => {
        this.resolveSingleAction(store, response, options);
      })
      .catch((error) => {
        this.rejectReadAction(
          store,
          // @ts-ignore
          String(i18n.global.t(error.response.data.error_message)),
          options
        );
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async findAll(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    return this.crudService
      .findAll(options)
      .then((response: any) => {
        this.resolveReadMultipleAction(store, response, options);
      })
      .catch((error) => {
        this.rejectReadAction(store, error, options);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async filter(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    return this.crudService
      .filter(options)
      .then((response: any) => {
        this.resolveReadMultipleAction(store, response, options);
      })
      .catch((error) => {
        this.rejectReadAction(store, error, options);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async create(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", !options.disabledLoading);
    store.commit("SET_SUCCESS", false);
    store.commit("SET_ERROR", null);

    return this.crudService
      .create(options)
      .then((response: any) => {
        const successMessage = options.successMessage
          ? options.successMessage
          : String(
              i18n.global.t("messages.save_success", {
                item: response.data[options.descriptionField],
              })
            );
        this.resolveWriteAction(store, response, successMessage, response.data, options);
        store.commit("SET_SUCCESS", true);
      })
      .catch((error) => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_SUCCESS", false);
      });
  }

  /**
   * Same as create, but receives a result also
   * @param store
   * @param options
   */
  public async simplePost(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", !options.disabledLoading);
    store.commit("SET_SUCCESS", false);
    store.commit("SET_DATA_ITEM", null);

    return this.crudService
      .create(options)
      .then((response: any) => {
        const successMessage = options.successMessage
          ? options.successMessage
          : String(
              i18n.global.t("messages.save_success", {
                item: response.data[options.descriptionField],
              })
            );
        this.resolveWriteAction(store, response, successMessage, response.data, options);
        store.commit("SET_SUCCESS", true);
        store.commit("SET_DATA_ITEM", response.data);
      })
      .catch((error) => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_SUCCESS", false);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async update(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    store.commit("SET_SUCCESS", false);
    store.commit("SET_DATA_ID", null);
    store.commit("SET_ERROR", null);
    const request: Promise<any> = this.crudService.update(options);
    request
      .then((response: any) => {
        const itemLabel: string = options.descriptionField;
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.global.t("messages.save_success", {
              item: itemLabel,
            })
          ),
          response.data,
          options
        );
        store.commit("SET_DATA_ID", response.data ? response.data : -1);
        store.commit("SET_SUCCESS", true);
      })
      .catch((error) => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_SUCCESS", false);
        store.commit("SET_DATA_ID", null);
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async delete(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    store.commit("SET_DATA_DELETED", false);
    return this.crudService
      .delete(options)
      .then((response: any) => {
        store.commit("SET_DATA_DELETED", true);
        const resourceName = options.resource;
        const itemLabel: string = options.descriptionField ? options.descriptionField : String(i18n.global.t("general." + resourceName));
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.global.t("messages.delete_success", {
              resourceName: itemLabel,
            })
          ),
          null,
          options
        );
        store.commit("SET_DATA_DELETED", true);
      })
      .catch((error) => {
        const { message, error_message } = error.response.data;
        this.rejectWriteAction(store, String(i18n.global.t(message ? message : error_message)), options);
        store.commit("SET_DATA_DELETED", false);
      });
  }

  public resolveWriteAction(store: ActionContext<any, any>, response: any, successMassage: string, item: any, options: ICrudOptions): void {
    if (!options.hideSuccessMessage) {
      this.dispatchSuccessMessage(store, successMassage);
    }
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on write action promise reject
   * @param store
   * @param error
   */
  public rejectWriteAction(store: ActionContext<any, any>, error: any, options: ICrudOptions): void {
    if (!options.hideErrorMessage) {
      this.dispatchError(store, error);
    }
    store.commit("SET_ERROR", error);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  public resolveReadMultipleAction(store: ActionContext<any, any>, response: any, options: ICrudOptions): void {
    if (response.data.data && response.data.data instanceof Array) {
      return this.resolveReadFilterAction(store, response, options);
    } else {
      return this.resolveReadFindAllAction(store, response, options);
    }
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  protected resolveReadFilterAction(store: ActionContext<any, any>, response: any, options: ICrudOptions): void {
    store.commit("SET_DATA_LIST", response.data.data);
    store.commit("SET_TOTAL", response.data.total);
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  protected resolveReadFindAllAction(store: ActionContext<any, any>, response: any, options: ICrudOptions): void {
    store.commit("SET_DATA_LIST", response.data);
    store.commit("SET_TOTAL", response.data.total);
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on read single action promise resolve
   * @param store
   * @param response
   */
  protected resolveSingleAction(store: ActionContext<any, any>, response: any, options: ICrudOptions): void {
    store.commit("SET_DATA_ITEM", response.data);
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on rad action promise reject
   * @param store
   * @param error
   */
  public rejectReadAction(store: ActionContext<any, any>, error: AxiosError, options: ICrudOptions) {
    const errorMassage = error.response?.data?.globalErrors ? error.response.data.globalErrors[0] : error;

    this.dispatchErrorMessage(store, errorMassage);
    store.commit("SET_ERROR", error);
    store.commit("IS_LOADING", false);
  }

  public async dispatchError(store: ActionContext<any, any>, error: AxiosError): Promise<void> {
    if (error.response?.status === 422) {
      let errorObject = error.response.data.fieldErrors && error.response.data.fieldErrors.length ? error.response.data.fieldErrors[0] : null;
      if (null === errorObject) {
        errorObject = error.response.data.globalErrors && error.response.data.globalErrors.length ? error.response.data.globalErrors[0] : null;
      }
      let errorMessage = "Unbekannter Fehler";
      if (!isString(errorObject)) {
        const errorMessage =
          null === errorObject || !errorObject.fieldName || !errorObject.error
            ? "Unbekannter Fehler"
            : errorObject.fieldName + " " + errorObject.error;
      } else {
        errorMessage = errorObject;
      }
      await this.dispatchErrorMessage(store, errorMessage);
    } else {
      await this.dispatchErrorMessage(store, error.message);
    }
  }
}
