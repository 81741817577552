export default () => {
  const onClickAppstore = (id = "1129247606") => {
    window.open(`https://apps.apple.com/app/id${id}`, "_blank");
  };
  const onClickGooglestore = (id = "de.fortbildung33.pruefungsapp") => {
    window.open(`https://play.google.com/store/apps/details?id=${id}&hl=de`, "_blank");
  };

  const onClickGoogleStoreDriveBuzz = () => {
    onClickGooglestore("de.drivebuzz");
  };

  const onClickAppStoreDriveBuzz = () => {
    onClickAppstore("1611736345");
  };

  return {
    onClickAppstore,
    onClickGooglestore,
    onClickGoogleStoreDriveBuzz,
    onClickAppStoreDriveBuzz,
  };
};
