import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";

export function SET_MANDATE_ID(state: State, mandateId: any) {
  state.mandateId = mandateId;
}
export function SET_AMOUNT_IN_EUR(state: State, amountInEur: any) {
  state.amountInEur = amountInEur;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_MANDATE_ID,
  SET_AMOUNT_IN_EUR,
};
