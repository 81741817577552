import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
//@ts-ignore
import VueRouteMiddleware from "vue-route-middleware";
import guest from "@/router/guest";
import { full_access } from "@/router/full_access";
import { basic_access } from "@/router/basic_access";

const routes: Array<RouteRecordRaw> = [...basic_access, ...full_access, ...guest];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  routes,
});

router.beforeEach(VueRouteMiddleware());

export default router;
