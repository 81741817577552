import { isPlatform } from "@ionic/vue";

const clickListener = (el: HTMLElement, binding: any, vnode: any, prevVnode: any) => {
  if (!isPlatform("desktop") || binding.value?.force === true) {
    //vnode.ctx.emit("mobile-click");
    binding.value?.onMobileClick();
  }
};

const ClickableOnMobile = {
  created(el: HTMLElement, binding: any, vnode: any, prevVnode: any) {
    el.addEventListener("click", () => clickListener(el, binding, vnode, prevVnode));
  },
  unmounted(el: HTMLElement, binding: any, vnode: any, prevVnode: any) {
    el.removeEventListener("click", () => clickListener(el, binding, vnode, prevVnode));
  },
};

export default ClickableOnMobile;
