import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";

export const livePayMandateActivate = async (store: any, options: any): Promise<any> => {
  store.commit("IS_LOADING", true);
  const request: Promise<any> = axios
    .post(`live-pay-mandate/from-third-party/${options.token}`, options.data)
    .then((response: any) => {
      store.commit("SET_MANDATE_ID", response.data);
      store.commit("IS_LOADING", false);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("IS_LOADING", false);
    });

  return request;
};

export const setAmountInEur = (store: any, amountInEur: any) => {
  store.commit("SET_AMOUNT_IN_EUR", amountInEur);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  livePayMandateActivate,
  setAmountInEur,
};
