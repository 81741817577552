import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, update } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";

export const getTheoryCourses = async (
  store: any,
  data: {
    drivingSchoolId: number;
    branchId: number;
    licenseClass: string;
  }
): Promise<any> => {
  store.commit("IS_LOADING", true);
  return axios
    .post(`/theory-courses/public-by-filter`, data)
    .then((response: any) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_THEORY_COURSES", response.data);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("IS_LOADING", false);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    });
};

export const getLicenceClasses = async (store: any, drivingSchoolId: number): Promise<any> => {
  store.commit("IS_LOADING", true);
  return axios
    .get(`/license-classes/by-driving-school?drivingSchoolId=${drivingSchoolId}`)
    .then((response: any) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_LICENSE_CLASSES", response.data);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("IS_LOADING", false);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    });
};

export const getDrivingSchool = async (store: any, drivingSchoolId: number): Promise<any> => {
  store.commit("IS_LOADING", true);
  return axios
    .get(`/public-info/driving-school/${drivingSchoolId}`)
    .then((response: any) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DRIVING_SCHOOL", response.data);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("IS_LOADING", false);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    });
};

export const getBranches = async (store: any, drivingSchoolId: number): Promise<any> => {
  store.commit("IS_LOADING", true);
  return axios
    .get(`/branches/by-driving-school?drivingSchoolId=${drivingSchoolId}`)
    .then((response: any) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_BRANCHES", response.data);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("IS_LOADING", false);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    });
};

export const getLanguages = async (store: any): Promise<any> => {
  store.commit("IS_LANGUAGES_LOADING", true);
  return axios
    .get(`/languages`)
    .then((response: any) => {
      store.commit("SET_LANGUAGES", response.data);
    })
    .catch((error) => {
      store.commit("SET_LANGUAGES_ERROR", error);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    })
    .finally(() => {
      store.commit("IS_LANGUAGES_LOADING", false);
    });
};

export const getCountries = async (store: any): Promise<any> => {
  store.commit("IS_COUNTRIES_LOADING", true);
  return axios
    .get(`/countries`)
    .then((response: any) => {
      store.commit("SET_COUNTRIES", response.data);
    })
    .catch((error) => {
      store.commit("SET_COUNTRIES_ERROR", error);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    })
    .finally(() => {
      store.commit("IS_COUNTRIES_LOADING", false);
    });
};

export const getPreviousLicenceClasses = async (store: any): Promise<any> => {
  store.commit("IS_PREVIOUS_LICENCE_CLASSES_LOADING", true);
  return axios
    .get(`/license-classes/base-license-classes`)
    .then((response: any) => {
      store.commit("SET_PREVIOUS_LICENCE_CLASSES", response.data);
    })
    .catch((error) => {
      store.commit("SET_PREVIOUS_LICENCE_CLASSES_ERROR", error);
      store.dispatch(
        "global-message/setMessage",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    })
    .finally(() => {
      store.commit("IS_PREVIOUS_LICENCE_CLASSES_LOADING", false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getTheoryCourses,
  getLicenceClasses,
  getDrivingSchool,
  getBranches,
  getLanguages,
  getCountries,
  getPreviousLicenceClasses,
};
