import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";

export const getAnswerSuccess = (state: State) => state.answerSuccess;
export const getQuestions = (state: State) => state.questions;
export const getBasicQuestions = (state: State) => state.basicQuestions;
export const getSpecificQuestions = (state: State) => state.specificQuestions;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getAnswerSuccess,
  getDataList,
  getTotal,
  getId,
  getQuestions,
  getBasicQuestions,
  getSpecificQuestions,
};
