import { State } from "./state";
import { GetterTree } from "vuex";

export const getSendThirdPartyPaymentEmailError = (state: State) => state.sendThirdPartyPaymentEmailError;
export const getSendThirdPartyPaymentEmailSuccess = (state: State) => state.sendThirdPartyPaymentEmailSuccess;
export const getIsSendThirdPartyPaymentEmailLoading = (state: State) => state.isSendThirdPartyPaymentEmailLoading;

export const getSelectedEducation = (state: State) => state.selectedEducation;

export default <GetterTree<State, any>>{
  getSendThirdPartyPaymentEmailError,
  getSendThirdPartyPaymentEmailSuccess,
  getIsSendThirdPartyPaymentEmailLoading,
  getSelectedEducation,
};
