import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";

export function SET_GROUPS(state: State, groups: any) {
  state.groups = groups;
}

export function SET_QUESTIONS(state: State, questions: any) {
  state.questions = questions;
}
export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_GROUPS,
  SET_QUESTIONS,
};
