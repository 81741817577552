import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";

export function SET_THEORY_COURSES(state: State, theoryCourses: any) {
  state.theoryCourses = theoryCourses;
}
export function SET_THEORY_COURSES_LOADING(state: State, payload: any) {
  state.theoryCoursesLoading = payload;
}

export function SET_LICENSE_CLASSES(state: State, licenceClasses: any) {
  state.licenceClasses = licenceClasses;
}

export function SET_LICENSE_CLASSES_LOADING(state: State, payload: any) {
  state.licenceClassesLoading = payload;
}

export function SET_DRIVING_SCHOOL(state: State, drivingSchool: any) {
  state.drivingSchool = drivingSchool;
}

export function SET_BRANCHES(state: State, branches: any) {
  state.branches = branches;
}
export function IS_LANGUAGES_LOADING(state: State, payload: boolean) {
  state.languagesLoading = payload;
}
export function SET_LANGUAGES(state: State, payload: any) {
  state.languages = payload;
}
export function SET_LANGUAGES_ERROR(state: State, payload: any) {
  state.languagesError = payload;
}
export function IS_COUNTRIES_LOADING(state: State, payload: boolean) {
  state.countriesLoading = payload;
}
export function SET_COUNTRIES(state: State, payload: any) {
  state.countries = payload;
}
export function SET_COUNTRIES_ERROR(state: State, payload: any) {
  state.countriesError = payload;
}
export function IS_PREVIOUS_LICENCE_CLASSES_LOADING(state: State, payload: any) {
  state.previousLicenceClassesLoading = payload;
}
export function SET_PREVIOUS_LICENCE_CLASSES(state: State, payload: any) {
  state.previousLicenceClasses = payload;
}
export function SET_PREVIOUS_LICENCE_CLASSES_ERROR(state: State, payload: any) {
  state.previousLicenceClassesError = payload;
}

export function SET_SELECTED_LICENCE_CLASSES(state: State, payload: any) {
  state.selectedLicenseClass = payload;
}

export function SET_SELECTED_THEORY_COURSE(state: State, payload: any) {
  state.selectedTheoryCourse = payload;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_THEORY_COURSES,
  SET_LICENSE_CLASSES,
  SET_DRIVING_SCHOOL,
  SET_BRANCHES,
  IS_LANGUAGES_LOADING,
  SET_LANGUAGES,
  SET_LANGUAGES_ERROR,
  IS_COUNTRIES_LOADING,
  SET_COUNTRIES,
  SET_COUNTRIES_ERROR,
  IS_PREVIOUS_LICENCE_CLASSES_LOADING,
  SET_PREVIOUS_LICENCE_CLASSES,
  SET_PREVIOUS_LICENCE_CLASSES_ERROR,
  SET_SELECTED_LICENCE_CLASSES,
  SET_SELECTED_THEORY_COURSE,
  SET_LICENSE_CLASSES_LOADING,
  SET_THEORY_COURSES_LOADING,
};
