import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_ctx.isDesktop)
    ? (_openBlock(), _createBlock(_component_b_modal, {
        key: 0,
        id: _ctx.id,
        size: _ctx.size,
        "hide-footer": _ctx.hideFooter,
        "hide-header": _ctx.hideHeader,
        "modal-class": _ctx.modalClass,
        "dialog-class": _ctx.dialogClass,
        "content-class": _ctx.contentClass,
        centered: _ctx.centered,
        "no-close-on-backdrop": _ctx.noCloseOnBackdrop,
        modelValue: _ctx.showModal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModal) = $event))
      }, {
        default: _withCtx(() => [
          (_ctx.showModal)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["id", "size", "hide-footer", "hide-header", "modal-class", "dialog-class", "content-class", "centered", "no-close-on-backdrop", "modelValue"]))
    : (_openBlock(), _createBlock(_component_ion_modal, {
        key: 1,
        "is-open": _ctx.showModal,
        id: _ctx.id,
        mode: "ios",
        breakpoints: _ctx.breakpoints,
        initialBreakpoint: _ctx.initialBreakpoint,
        onWillDismiss: _ctx.willDismiss,
        class: _normalizeClass(_ctx.customClass)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  (_ctx.title)
                    ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_buttons, {
                    slot: "end",
                    class: _normalizeClass({ 'd-none': _ctx.hideCloseBtn })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hide()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("general.close")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, {
            class: _normalizeClass(_ctx.ionContentClass)
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["class"])
        ]),
        _: 3
      }, 8, ["is-open", "id", "breakpoints", "initialBreakpoint", "onWillDismiss", "class"]))
}