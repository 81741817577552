import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update, simplePost } from "@/store/Crud/functions/actions";

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  simplePost,
};
