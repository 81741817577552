import { Module, ModuleTree } from "vuex";
import { State } from "./state";
import Mutations from "./mutations";
import Getters from "./getters";
import Actions from "./actions";
import Upload from "@/store/Abstract/Upload";
import Update from "@/store/Abstract/Update";

export default class Student implements Module<State, any> {
  namespaced = true;
  state: State;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;
  modules: ModuleTree<any>;

  constructor() {
    this.state = new State();
    this.modules = {
      uploadAvatar: new Upload(),
      acceptContract: new Update(),
    };
  }
}
