import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update, simplePost } from "@/store/Crud/functions/actions";

// TODO code duplication
export const setLoading = async (store: any, loading: boolean): Promise<void> => {
  store.commit("IS_LOADING", loading);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  setLoading,
  simplePost,
};
